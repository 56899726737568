import AbstractService from "./AbstractService";
import Axios from "axios";

export default class ShippingFeeService extends AbstractService {
	private controller = "shipping-fees";

	public get(q: any = null) {
		const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
	}
    
    public calculate(shippingAddressId: number, totalAmount: number, totalWeight: number, deliveryMethod: string) {
        var q = { shippingAddressId, totalAmount, totalWeight, deliveryMethod };
        const url = this.makeUrlWithQueries(`${this.controller}/calculate`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

	public post(shippingFee: any) {
        const d = { shippingFee };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(shippingFee: any) {
        const d = { shippingFee };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(shippingFee: any) {
        const d = { shippingFee };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }
}
