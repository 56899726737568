












































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import EventManager from "@/utilities/EventManager";
import { ShippingFeeInfoProperties } from "./ShippingFeeInfoView.vue";

const ShippingFeeRegionCountryView = () => import("./ShippingFeeRegionCountryView.vue");
const ShippingFeeRegionStateView = () => import("./ShippingFeeRegionStateView.vue");
const ShippingFeeRegionCityView = () => import("./ShippingFeeRegionCityView.vue");

class ShippingFeeRegionDialogProperties {
    visible: boolean = false;
    allCountries: boolean = true;
    allStates: boolean = true;
    allCities: boolean = true;
    selectedCountries: any[] = [];
    selectedStates: any[] = [];
    selectedCities: any[] = [];
    events = new EventManager();
}

export { ShippingFeeRegionDialogProperties };

@Component({
    components: { ShippingFeeRegionCountryView, ShippingFeeRegionStateView, ShippingFeeRegionCityView },
    data: () => ({
        tab: null,
        page: null
    })
})
export default class ShippingFeeRegionDialogView extends Vue {
    @Prop({ default: new ShippingFeeRegionDialogProperties() })
    private properties: ShippingFeeRegionDialogProperties;
    @Prop() private shippingFeeInfo: ShippingFeeInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    public async submit() {
        const countries = this.properties.selectedCountries;
        const states = this.properties.selectedStates;
        const cities = this.properties.selectedCities;

        if (this.properties.allCountries) {
            this.addAllCountries();
        } else if (this.properties.allStates) {
            this.addAllStates();
        } else if (this.properties.allCities) {
            this.addAllCities();
        } else {
            this.addSelectedCities();
        }
        await this.shippingFeeInfo.events.fire('sort-regions');
    }

    public addAllCountries() {
        const regions = this.shippingFeeInfo.shippingFee.regions;

        var tmp = regions.filter(x => !x.countryId && !x.stateId && !x.cityId);
        if (tmp.length === 0) {
            regions.push({
                tempId: this.generateTempId(),
                countryText: "All",
                stateText: "All",
                cityText: "All"
            });
        }
        this.close();
    }

    public addAllStates() {
        const countries = this.properties.selectedCountries;
        const regions = this.shippingFeeInfo.shippingFee.regions;

        countries.forEach(e => {
            var tmp = regions.filter(x => x.countryId === e.id && !x.stateId && !x.cityId);
            if (tmp.length === 0) {
                regions.push({
                    tempId: this.generateTempId(),
                    countryId: e.id,
                    countryText: e.name,
                    country: e,
                    stateText: "All",
                    cityText: "All"
                });
            }
        });
        this.close();
    }

    public addAllCities() {
        const countries = this.properties.selectedCountries;
        const states = this.properties.selectedStates;
        const regions = this.shippingFeeInfo.shippingFee.regions;

        states.forEach(e => {
            var tmp = regions.filter(x => !x.countryId && x.stateId === e.id && !x.cityId);
            if (tmp.length === 0) {
                regions.push({
                    tempId: this.generateTempId(),
                    countryText: e.country.name,
                    stateId: e.id,
                    stateText: e.name,
                    state: e,
                    cityText: "All"
                });
            }
        });
        countries.forEach(e => {
            var tmp = states.filter(x => x.countryId === e.id);
            if (tmp.length === 0) {
                tmp = regions.filter(x => x.countryId === e.id && !x.stateId && !x.cityId);
                if (tmp.length === 0) {
                    regions.push({
                        tempId: this.generateTempId(),
                        countryId: e.id,
                        countryText: e.name,
                        country: e,
                        stateText: "All",
                        cityText: "All"
                    });
                }
            }
        });
        this.close();
    }

    public addSelectedCities() {
        const countries = this.properties.selectedCountries;
        const states = this.properties.selectedStates;
        const cities = this.properties.selectedCities;
        const regions = this.shippingFeeInfo.shippingFee.regions;

        cities.forEach(e => {
            var tmp = regions.filter(x => !x.countryId && !x.stateId && x.cityId === e.id);
            if (tmp.length === 0) {
                regions.push({
                    tempId: this.generateTempId(),
                    countryText: e.state.country.name,
                    stateText: e.state.name,
                    cityId: e.id,
                    cityText: e.name,
                    city: e
                });
            }
        });
        states.forEach(e => {
            var tmp = cities.filter(x => x.stateId === e.id);
            if (tmp.length === 0) {
                tmp = regions.filter(x => !x.countryId && x.stateId === e.id && !x.cityId);
                if (tmp.length === 0) {
                    regions.push({
                        tempId: this.generateTempId(),
                        countryText: e.country.name,
                        stateId: e.id,
                        stateText: e.name,
                        state: e,
                        cityText: "All"
                    });
                }
            }
        });
        countries.forEach(e => {
            var tmp1 = cities.filter(x => x.state.countryId === e.id);
            var tmp2 = states.filter(x => x.countryId === e.id);
            if (tmp1.length === 0 || tmp2.length === 0) {
                var tmp = regions.filter(x => x.countryId === e.id && !x.stateId && !x.cityId);
                if (tmp.length === 0) {
                    regions.push({
                        tempId: this.generateTempId(),
                        countryId: e.id,
                        countryText: e.name,
                        country: e,
                        stateText: "All",
                        cityText: "All"
                    });
                }
            }
        });
        this.close();
    }

    public generateTempId() {
        const regions = this.shippingFeeInfo.shippingFee.regions;

        while (true) {
            var tempId = Math.random();
            if (!regions.find(x => x.tempId === tempId)) {
                return tempId;
            }
        }
    }

    public close() {
        this.properties.visible = false;
    }

    public async tabChanged(e) {
        if (this.$data.tab === 1) {
            const countries = this.properties.selectedCountries;
            if (countries.length === 0) {
                await Vue.nextTick();
                this.$data.tab = this.$data.page;
                var m = this.$t("message.country-nothing-selected");
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
            await this.properties.events.fire("load-states");
        } else if (this.$data.tab === 2) {
            const states = this.properties.selectedStates;
            if (states.length === 0) {
                await Vue.nextTick();
                this.$data.tab = this.$data.page;
                var m = this.$t("message.state-nothing-selected");
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
            await this.properties.events.fire("load-cities");
        }

        this.$data.page = this.$data.tab;
    }
}
