
































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ShippingFeeInfoProperties } from "./ShippingFeeInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import AutoNumeric, { Options } from "autonumeric";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

class ShippingFeePricingDialogProperties {
    isNew: boolean = false;
    visible: boolean = false;
    pricing: any = {};
}

export { ShippingFeePricingDialogProperties };

@Component({})
export default class ShippingFeePricingDialogView extends Vue {
    @Prop({ default: new ShippingFeePricingDialogProperties() })
    private properties: ShippingFeePricingDialogProperties;
    @Prop() private shippingFeeInfo: ShippingFeeInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private inputMinimumPaymentAmount: HTMLInputElement;
    private inputMaximumPaymentAmount: HTMLInputElement;
    private inputMinimumWeight: HTMLInputElement;
    private inputMaximumWeight: HTMLInputElement;
    private inputWeightStep: HTMLInputElement;
    private inputWeightPrice: HTMLInputElement;

    public get pricing() {
        return this.properties.pricing;
    }

    public mounted() {
        var dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputMinimumPaymentAmount = dialog.$el.querySelector("#minimum-payment-amount");
            this.inputMaximumPaymentAmount = dialog.$el.querySelector("#maximum-payment-amount");
            this.inputMinimumWeight = dialog.$el.querySelector("#minimum-weight");
            this.inputMaximumWeight = dialog.$el.querySelector("#maximum-weight");
            this.inputWeightStep = dialog.$el.querySelector("#weight-step");
            this.inputWeightPrice = dialog.$el.querySelector("#weight-price");

            this.updateFormatter();
        }
    }

    public updateFormatter() {
        setTimeout(() => {
            const options: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };

            AutoNumericUtil.findOrNew(this.inputMinimumPaymentAmount, null, options)
                .set(this.pricing.minimumPaymentAmount ?? 0);
            AutoNumericUtil.findOrNew(this.inputMaximumPaymentAmount, null, options)
                .set(this.pricing.maximumPaymentAmount ?? 0);
            AutoNumericUtil.findOrNew(this.inputMinimumWeight, null, options)
                .set(this.pricing.minimumWeight ?? 0);
            AutoNumericUtil.findOrNew(this.inputMaximumWeight, null, options)
                .set(this.pricing.maximumWeight ?? 0);
            AutoNumericUtil.findOrNew(this.inputWeightStep, null, options)
                .set(this.pricing.weightStep ?? 0);
            AutoNumericUtil.findOrNew(this.inputWeightPrice, null, options)
                .set(this.pricing.weightPrice ?? 0);
        }, 150);
    }

    public generateTempId() {
        const pricings = this.shippingFeeInfo.shippingFee.pricings;

        while (true) {
            var tempId = Math.random();
            if (!pricings.find((x) => x.tempId === tempId)) {
                return tempId;
            }
        }
    }

    public submit() {
        const minimumPaymentAmount = Number(this.pricing.minimumPaymentAmount ?? 0);
        const maximumPaymentAmount = Number(this.pricing.maximumPaymentAmount ?? 0);
        const minimumWeight = Number(this.pricing.minimumWeight ?? 0);
        const maximumWeight = Number(this.pricing.maximumWeight ?? 0);
        const weightStep = Number(this.pricing.weightStep ?? 0);
        const weightPrice = Number(this.pricing.weightPrice ?? 0);
        const name = "message.shipping-fee-pricing-description";
        const pricings: any[] = this.shippingFeeInfo.shippingFee.pricings;

        this.pricing.paymentAmount = `${minimumPaymentAmount.toFixed(2)} — ${maximumPaymentAmount.toFixed(2)}`;
        this.pricing.weight = `${minimumWeight.toFixed(2)} — ${maximumWeight.toFixed(2)}`;
        this.pricing.description = this.$t(name, [weightStep.toFixed(2), weightPrice.toFixed(2)]);

        if (this.properties.isNew) {
            this.pricing.tempId = this.generateTempId();               
            pricings.push(this.pricing);
        } else {
            var tmp = pricings.filter(x => x.tempId === this.pricing.tempId);
            var index = pricings.indexOf(tmp[0]);
            Vue.set(pricings, index, this.pricing);
        }
        this.properties.visible = false;
    }

    public close() {
        this.properties.visible = false;
    }
}
