








































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { ShippingFeeRegionDialogProperties } from "./ShippingFeeRegionDialogView.vue";
import { ShippingFeePricingDialogProperties } from "./ShippingFeePricingDialogView.vue";
import EventManager from "@/utilities/EventManager";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CommonUtil from "@/utilities/CommonUtil";
import ShippingFeeService from "@/services/ShippingFeeService";

const EditorControlView = () => import("@/components/shared/EditorControlView.vue");
const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const ShippingFeeRegionView = () => import("./ShippingFeeRegionView.vue");
const ShippingFeePricingView = () => import("./ShippingFeePricingView.vue");
const ShippingFeeRegionDialogView = () => import("./ShippingFeeRegionDialogView.vue");
const ShippingFeePricingDialogView = () => import("./ShippingFeePricingDialogView.vue");

class ShippingFeeInfoProperties {
    shippingFee: any = { regions: [], pricings: [] };
    events = new EventManager();
}

export { ShippingFeeInfoProperties };

@Component({
    components: {
        EditorControlView,
        AlertDialogView,
        ShippingFeeRegionView,
        ShippingFeePricingView,
        ShippingFeeRegionDialogView,
        ShippingFeePricingDialogView
    },
    data: () => ({
        tab: null,
        saving: false,
    }),
})
export default class ShippingFeeInfoView extends Mixins(InfoViewMixin) {
    private properties = new ShippingFeeInfoProperties();
    private editorControl = new EditorControlProperties();
    private alertDialog = new AlertDialogProperties();
    private shippingFeeRegionDialog = new ShippingFeeRegionDialogProperties();
    private shippingFeePricingDialog = new ShippingFeePricingDialogProperties();
    private shippingFeeService = new ShippingFeeService();

    public get locale() {
        return this.$i18n.locale;
    }

    public get shippingFee() {
        return this.properties.shippingFee;
    }

    public async mounted() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            await this.load();
        }
    }

    public refreshRegions() {
        const regions: any[] = this.properties.shippingFee.regions;
        for (var i = 0; i < regions.length; i++) {
            var tempId = Math.random();
            if (!regions.find(x => x.tempId === tempId)) {
                var n = regions[i];
                n.tempId = tempId;
                if (n.countryId) {
                    n.countryText = n.country.name;
                    n.stateText = "All";
                    n.cityText = "All";
                } else if (n.stateId) {
                    n.countryText = n.state.country.name;
                    n.stateText = n.state.name;
                    n.cityText = "All";
                } else if (n.cityId) {
                    n.countryText = n.city.state.country.name;
                    n.stateText = n.city.state.name;
                    n.cityText = n.city.name;
                } else {
                    n.countryText = "All";
                    n.stateText = "All";
                    n.cityText = "All";
                }
            } else {
                i--;
            }
        }
    }

    public refreshPricings() {
        const pricings: any[] = this.properties.shippingFee.pricings;
        for (var i = 0; i < pricings.length; i++) {
            var tempId = Math.random();
            if (!pricings.find(x => x.tempId === tempId)) {
                pricings[i].tempId = tempId;
            } else {
                i--;
            }
        }

        pricings.forEach(e => {
            const minimumPaymentAmount = Number(e.minimumPaymentAmount);
            const maximumPaymentAmount = Number(e.maximumPaymentAmount);
            const minimumWeight = Number(e.minimumWeight);
            const maximumWeight = Number(e.maximumWeight);
            const weightStep = Number(e.weightStep);
            const weightPrice = Number(e.weightPrice);
            const name = "message.shipping-fee-pricing-description";

            e.paymentAmount = `${minimumPaymentAmount.toFixed(2)} — ${maximumPaymentAmount.toFixed(2)}`;
            e.weight = `${minimumWeight.toFixed(2)} — ${maximumWeight.toFixed(2)}`;
            e.description = this.$t(name, [e.weightStep.toFixed(2), e.weightPrice.toFixed(2)]);
        });
    }

    public async load() {
        try {
            if (this.isCreate()) {
                this.properties.shippingFee = {
                    weightAbove: 0.0,
                    weightStep: 0.0,
                    weightPrice: 0.0,
                    regions: [],
                    pricings: []
                };
            } else if (this.isModify()) {
                var id = this.$route.query.id;
                const r = await this.shippingFeeService.get({
                    id,
                    loadRegions: true,
                    loadRegionCountry: true,
                    loadRegionState: true,
                    loadRegionCity: true,
                    loadPricings: true
                });

                if (r.data.shippingFees.length === 0) {
                    throw new Error(this.$t("message.record-not-found").toString());
                }

                const shippingFee = r.data.shippingFees[0];
                if (!shippingFee.regions) shippingFee.regions = [];
                if (!shippingFee.pricings) shippingFee.pricings = [];

                this.properties.shippingFee = shippingFee;
                this.refreshRegions();
                this.refreshPricings();
                await this.properties.events.fire("sort-regions");
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            var shippingFee = this.properties.shippingFee;
            if (this.isCreate()) {
                const r = await this.shippingFeeService.post(shippingFee);     

                this.properties.shippingFee = r.data;
                this.refreshRegions();
                this.refreshPricings();
                await this.properties.events.fire("sort-regions");

                const shippingFeeId = this.properties.shippingFee.id;
                await this.$router.push("/management/sales/shipping-fees/modify?id=" + shippingFeeId);
                this.mode = "modify";

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            } else if (this.isModify()) {
                const r = await this.shippingFeeService.put(shippingFee);

                this.properties.shippingFee = r.data;
                this.refreshRegions();
                this.refreshPricings();
                await this.properties.events.fire("sort-regions");

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async create() {
        await this.$router.push("/management/sales/shipping-fees/create");
        this.mode = "create";
        await this.load();
    }

    public async remove() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.shippingFeeService.delete(this.shippingFee);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "save") {
            await this.save();
        } else if (button === "create") {
            await this.create();
        } else if (button === "cancel") {
            this.cancel();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "discard-changes" && button === this.$t("text.yes")) {
                await this.load();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted")) {
            await this.$router.push("/management/sales/shipping-fees");
        }
    }
}
